<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>


    <div v-if="currentEvent.id">

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-center font-weight-regular display-2">
            <h4>{{currentEvent.name}}</h4>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
  
      <v-divider />

      <v-list expand nav>
        <base-item :item="{icon: 'mdi-calendar-heart', title: 'Event Details', to: '/Dashboard/EventDetails/' + currentEvent.id}"/>
        <base-item :item="{icon: 'mdi-clock-time-four', title: 'Event Times', to: '/Dashboard/EventTimes/' + currentEvent.id}"/>
        <base-item :item="{icon: 'mdi-ticket', title: 'Ticket Types', to: '/Dashboard/TicketTypes/' + currentEvent.id}"/>
        <base-item :item="{icon: 'mdi-chart-line', title: 'Reports & Downloads', to: '/Dashboard/EventReports/' + currentEvent.id}"/>
      </v-list>
  
      <v-divider />
  
    </div>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-center font-weight-regular display-2">
          <h4 v-if="currentBoxOffice.id">{{currentBoxOffice.name}}</h4>
          <h4 v-else>Openstage</h4>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list expand nav>
      <div v-if="currentBoxOffice.id">
        <base-item :item="{icon: 'mdi-calendar-month', title: 'Events', to: '/Dashboard/Events/' + currentBoxOffice.id}"/>
        <base-item :item="{icon: 'mdi-tshirt-crew', title: 'Products', to: '/Dashboard/Products/' + currentBoxOffice.id}"/>
        <base-item :item="{icon: 'mdi-cog', title: 'Settings', to: '/Dashboard/BoxOfficeSettings/' + currentBoxOffice.id}"/>
        <!-- <base-item :item="{icon: 'mdi-account-multiple', title: 'Users', to: '/Dashboard/Users/' + currentBoxOffice.id}"/> -->
        <base-item :item="{icon: 'mdi-chart-line', title: 'Sales Summary', to: '/Dashboard/BoxOfficeSales/' + currentBoxOffice.id}"/>
        <base-item :item="{icon: 'mdi-magnify', title: 'All Orders', to: '/Dashboard/AllSearch/' + currentBoxOffice.id}"/>
        <base-item :item="{icon: 'mdi-auto-fix', title: 'New Event Wizard', to: '/Dashboard/Wizard/' + currentBoxOffice.id}"/>
      </div>
      <div v-else>
        <base-item :item="{icon: 'mdi-ticket-account', title: 'Your Box Offices', to: '/Dashboard/BoxOffices'}"/>
        <base-item :item="{icon: 'mdi-auto-fix', title: 'New Event Wizard', to: '/Dashboard/Wizard'}"/>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import Store from '@/services/Store.js';
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    components: {
      BaseItem: () => import('@/components/base/Item'),
    },  

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      currentBoxOffice: Store.boxOffice,
      currentEvent: Store.event,
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
